.loading {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:white;
    opacity:0.7;
    box-sizing:border-box;
    z-index:10000;
    .spinner {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        --webkit-transform:translate(-50%,-50%);
        --ms-transform:translate(-50%,-50%);
        box-sizing:border-box;
    }
}