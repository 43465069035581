$link_color: black;
$text_color: black;
$second_text_color: #444444;
$twitter_blue_color: #1da1f2;
$secondary_color: #f50057;
$method1_color: green;
$method2_color: orangered;

*,*::before,*::after{box-sizing:border-box}body,h1,h2,h3,h4,p,figure,blockquote,dl,dd{margin:0}ul[role="list"],ol[role="list"]{list-style:none}html:focus-within{scroll-behavior:smooth}body{min-height:100vh;text-rendering:optimizeSpeed;line-height:1.5}a:not([class]){text-decoration-skip-ink:auto}img,picture{max-width:100%;display:block}input,button,textarea,select{font:inherit}@media(prefers-reduced-motion:reduce){html:focus-within{scroll-behavior:auto}*,*::before,*::after{animation-duration:.01ms !important;animation-iteration-count:1 !important;transition-duration:.01ms !important;scroll-behavior:auto !important}}

html {
	padding:0;
	margin:0;
}

body {
	background-color: #282c34;
	position:relative;
	padding:0;
	margin:0;
	//background-color:white;
}

.link {
	text-decoration:none;
}

.withIcon {
	display:inline-flex;
	vertical-align:middle;
}


.welcome {
	div.image {
		border-top:1px solid silver;
		border-bottom:1px solid silver;
	}
	div.btn_wide {
		text-align:center;
		padding:2em 0;
		padding-bottom:6em;
		p {
			font-size:90%;
			line-height:1.8em;
		}
		button {
			margin-top:2em;
		}
	}
	div.btn {
		text-align:left;
		padding:2em 1em;
		padding-bottom:6em;
		p {
			font-size:90%;
			line-height:1.5em;
			margin-bottom:0.5em;
		}
		button {
			margin-top:2em;
			width:100%;
		}
	}
}

.samples {
	margin-bottom:3em;
}

.main {
	width:100%;
	max-width:800px;
	margin:0 auto;
	color:$text_color;
	background-color:white;
	padding-bottom:3em;
}

.dialog {
	padding-bottom:1em;
}

.about {
	padding-bottom:2em;
	p {
		margin-bottom:1em;
		color:#333333;
	}
	table {
		border-collapse:collapse;
		width:100%;
		margin-top:2em;
		th,td {
			border:1px solid silver;
			color:#333333;
			padding:0.25em;
			font-size:90%;
		}
	}
}

.documentHelp {
	margin-top:2em;
	margin-bottom: 1em;
	dt {
		border-bottom: 1px solid silver;
		margin-bottom: 0.5em;
		font-size: 1em;
		font-weight:bold;
		color:#333333;
	}
	dd {
		margin: 0;
		padding: 0;
		padding-left: 0.5em;
		margin-bottom: 2em;
		ol {
			margin: 0.5em 0;
			padding: 0;
			li {
				margin: 0;
				padding: 0;
				margin-left: 2em;
				margin-bottom: 0.5em;
			}
		}
		ul {
			margin: 0.5em 0;
			padding: 0;
			li {
			  margin: 0;
			  padding: 0;
			  margin-left: 2em;
			  margin-bottom: 0.5em;
			  color:#333333;
			}
		}
	}
	.link {
		color:$text_color;
		text-decoration:underline;
	}
}

.signature {
	text-align: right;
}



.drawer {
	.authData {
		min-width:320px;
		//border:1px solid silver;
		//margin:0.5em;
		padding:0em 1em;
		h3 {
			margin:0;
			margin-bottom:0.5em;
		}
		ul {
			margin:0;
			padding:0;
			margin-bottom:2em;
			list-style:none;
			li {
				margin:0;
				padding:0;
				color:black;
			}
		}
	}
	.menu {
		min-width:320px;
		padding:2em 1em;
	}
}

.form {
	margin-bottom:1em;
}

.formMargin {
	margin-bottom:0.3em;
}

.mailLoginInput {
	margin-top:2em;
}

div.verBox {
	text-align:center;
	border:1px solid silver;
	padding:1em;
	h3 {
	  font-size:80%;
	  font-weight:normal;
	  color:#333333;
	  margin:0;
	}
	div {
	  font-size:180%;
	}
	div.latest {
	  color: green;
	}
	div.current {
	  color: pink;
	}
}
  
div.verGuide {
	margin-bottom:2em;
	h3 {
		font-size:100%;
		margin-bottom:0.5em;
	}
	div {
		font-size:90%;
		p {
		font-size:80%;
		color:#888888;
		}
	}
}

//react-suggest
//https://codepen.io/moroshko/pen/XRgbxR
//https://dackdive.hateblo.jp/entry/react-autosuggest
.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}



//shared
a {
	text-decoration:none;
}

a.link {
	color:$link_color;
}

a.menuLink {
	color: #212121;
}

.shortUrl {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display:block;
	max-width:20em;
}

div.screen {
	padding:0 1.5em;
}

.fab {
	position:fixed;
	right:2em;
	bottom:2em;
}

div.profile {
	margin-bottom:2em;
	div.image {
		position:relative;
		text-align:center;
		line-height:0;
		background-color:#F5F5F5;
		img {
			max-width:100%;
			margin:0 auto;
		}
		div.buttonUrlBack {
			position:absolute;
			bottom:2em;
			right:6em;
			color:black;
			background-color:#000000;
			opacity:0.3;
			padding:1em 0em;
			height:3em;
			width:9em;
			box-sizing:border-box;
			line-height:1em;
			font-size:90%;
		}
		button.buttonUrl {
			position:absolute;
			bottom:2em;
			right:6em;
			color:white;
			padding:1em 0em;
			padding-left:1.5em;
			width:9em;
			height:3em;
			box-sizing:border-box;
			line-height:1em;
			font-size:90%;
			background-color:transparent;
			border-width:0;
			font-weight:normal;
			cursor:pointer;
			span {
				position:absolute;
				left:1em;
				top:0.65em;
			}
		}
		div.buttonCameraBack {
			position:absolute;
			bottom:2em;
			right:2em;
			color:black;
			background-color:#000000;
			opacity:0.3;
			padding:1em 0em;
			height:3em;
			width:3em;
			box-sizing:border-box;
			line-height:1em;
			font-size:90%;
		}
		button.buttonCamera {
			position:absolute;
			bottom:2em;
			right:2em;
			color:white;
			padding:1em 0em;
			width:3em;
			height:3em;
			box-sizing:border-box;
			line-height:1em;
			font-size:90%;
			background-color:transparent;
			border-width:0;
			font-weight:normal;
			cursor:pointer;
			span {
				position:absolute;
				left:0.7em;
				top:0.7em;
			}
		}
		div.isWaiting {
			position:absolute;
			bottom:2em;
			right:2em;
			color:white;
			padding:0em;
			width:3em;
			height:3em;
			box-sizing:border-box;
			line-height:1em;
			font-size:90%;
		}
	}
	div.text {
		//position:relative;
		padding-top:1em;
		//padding:0 1.5em;
		div.name {
			font-size:140%;
		}
		div.phrase {
			font-size:90%;
			color:$second_text_color;
			span {
				margin-right:0.25em;
			}
			a {
				text-decoration:none;
				.iconTwitter {
					font-size:100%;
					color: $twitter_blue_color;
				}
			}
		}
		div.hp {
			font-size:90%;
			color:$second_text_color;
			a {
				color:$second_text_color;
				text-decoration:none;
			}
		}
	}
	div.url {
		text-align:right;
		background-color:#999999;
	}
}

div.viewModeMenu {
	//position:relative;
	//border-top:3px double silver;
	//padding:0 0.5em;
	//padding-top:8px;
	ul {
		margin:0;
		padding:0;
		height:4em;
		list-style:none;
		//background-color:red;
		li {
			margin:0;
			padding:1em 0;
			float:left;
			font-size:80%;
			color:$second_text_color;
			cursor:pointer;
			width:8em;
			text-align:center;
		}
		li.active {
			color:$text_color;
			border-bottom:3px solid $text_color;
		}
		li.sns {
			float:right;
			text-align:right;
			margin-right:0;
			width:auto;
			a {
				margin-left:1em;
			}
		}
	}
}

div.stages {
	padding-bottom:4em;
	div.year {
		margin-bottom:0.5em;
		//background-color:#666666;
		//border-bottom:2px solid #CCCCCC;
		//border-top:2px solid #CCCCCC;
		//text-align:right;
		//font-size:150%;
		//padding:0.25em;
		color:#999999;
		text-align:left;
		line-height:1.0em;
	}
	div.stage {
		position:relative;
		margin-bottom:3em;
		line-height:1.2em;
		//border-top:1px solid #666666;
		//border-top:1px dashed khaki;
		//padding-top:1em;
		div.leftSide {
			position:absolute;
			top:0;
			left:0;
			width:8em;
			div.date {
				font-size:150%;
				line-height:1.25em;
				margin-bottom:0.25em;
				letter-spacing:0.05em;
				span.week {
					font-size:50%;					
				}
				span.weekSun {
					color:brown;
				}
				span.weekSat {
					color:navy;
				}
				span.weekHol {
					color:brown;
				}
			}
			div.condition {
				font-size:80%;
				span.method {
					margin-right:0.5em;
					//background-color:#444444;
					//color:#333333;
					border:1px solid #CCCCCC;
					color:#CCCCCC;
					padding:0 0.25em;
				}
				span.method1 {
					//background-color:green;
					//color:white;				
					border:1px solid $method1_color;
					color:$method1_color;
				}
				span.method2 {
					//background-color:orange;
					//color:white;
					border:1px solid $method2_color;
					color:$method2_color;
				}
				span.condition {
					margin-right:0.5em;
					background-color:red;
					color:white;
					padding:0 0.5em;
				}
	
			}
		}
		div.rightSide {
			position:relative;
			margin-left:8em;
			padding-right:0.25em;
			padding-bottom:3em;
			div.primary {
				padding-bottom:0.5em;
				margin-bottom:2.0em;
				div.mainTitle {
					font-size:150%;
					line-height:1.25em;
				}
				div.second {
					margin-top:0.25em;
					color:#666666;
					font-size:90%;
				}
				div.stream_url {
					margin-top:0.25em;
					font-size:90%;
					color:#666666;
					a {
						color:#666666;
					}
				}
			}
			div.detail {
				//display:none;
				//background-color:#333333;
				//padding:0.25em 0em;
				//padding:1em;
				//padding-left:1em;
				font-size:90%;
				//border:1px solid khaki;
				div.subTitle {
					margin-bottom:0.5em;
					//font-weight:bold;
					color:$text_color;
				}
				div.artists {
					font-size:90%;
					line-height:1.5em;
					color:#666666;
					margin-bottom:0.5em;
					word-wrap: break-word;
					overflow-wrap: break-word;
					a {
						color: #666666;
					}
				}
				div.memo {
					font-size:90%;
					line-height:1.5em;
					color:#666666;
					margin-bottom:0.5em;
					word-wrap: break-word;
					overflow-wrap: break-word;
					a {
						color: #666666;
					}
				}
				div.btnDetail {
					position:relative;
					margin-top:2em;
					a {
						border:1px solid #333333;
						border-radius:1em;
						padding:0.25em 1em;
						vertical-align:middle;
						color: #333333;
					}
					div.actions {
						position:absolute;
						right:-0.5em;
						bottom:-0.5em;
						line-height:0;
					}		
				}
			}
		}
	}
	div.more {
		text-align:center;
		color: $second_text_color;
	}
	div.noMore {
		text-align:center;
		color: $second_text_color;
	}
	div.noItems {
		text-align:center;
		color: $second_text_color;
		padding-top:4em;
	}
}

div.story {
	//font-size:90%;
	padding-top:2em;
	padding-bottom:4em;
	line-height:1.5em;
	div.noItems {
		text-align:center;
		color: $second_text_color;
		padding-top:2em;
	}
	div.inner {
		font-size:90%;
	}
}

div.footer {
	text-align:center;
	padding-top:6em;
	div.logo {
		a {
			color:$text_color;
			text-decoration:none;
		}		
		img {
			width:30px;
			display:inline;
		}
	}
}

div.dialogFooter {
	text-align:center;
	padding-top:3em;
	margin-bottom:3em;
	div.logo {
		a {
			color:$text_color;
			text-decoration:none;
		}		
		img {
			width:30px;
			display:inline;
		}
	}
}


@media screen and (max-width: 479px){
	div.screen {
		padding:0 1em;
	}
	div.stages {
		div.stage {
			div.rightSide {
				margin-left:7em;
			}
		}
	}
	div.viewModeMenu {
		ul {
			li {
				width:25%;
			}
		}
	}
}

@media screen and (max-width: 339px){
	div.viewModeMenu {
		ul {
			li#tabShort {
				width:22%;
			}
			li#tabLong {
				width:28%;
			}
		}
	}
}